/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Helvetica", sans-serif;
  color: black;
  background: #EFF3F6;
  font-size: 14px;
}

::-webkit-scrollbar-track {
  border-radius: 12px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #aaa;
}

* {
  scrollbar-color: #aaa #f5f5f5;
  scrollbar-width: thin;
}

* {
  scrollbar-color: #aaa #f5f5f5;
  scrollbar-width: thin;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2e3c40;
}

a {
  color: #608aba;
  cursor: pointer;
}

a:hover {
  color: #41658d;
}


.break-two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.break-three {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.react-datepicker-popper {
  z-index: 11!important;
}